// ** Next Import
import { Link } from 'react-router-dom'
// ** MUI Imports
import Box from '@mui/material/Box'
// ** Theme Config Import
import Logo from '../../../../../assets/images/Group2.png'

const AppBarContent = props => {
  // ** Props
  const {
    horizontalAppBarContent: userHorizontalAppBarContent,
    horizontalAppBarBranding: userHorizontalAppBarBranding
  } = props

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {userHorizontalAppBarBranding ? (
        userHorizontalAppBarBranding(props)
      ) : (
        <Link to='/'>
          <img src={Logo} alt='logo' style={{ width: "90px", height: "90px" }} />
        </Link>
      )}
      {userHorizontalAppBarContent ? userHorizontalAppBarContent(props) : null}
    </Box>
  )
}

export default AppBarContent
