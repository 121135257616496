import React, { useEffect } from 'react'
import { Container, Box, Typography, Grid, Divider } from '@mui/material'
import FbImg from '../../assets/images/Footer1.png'
import TwitterImg from '../../assets/images/Footer2.png'
import UtubeImg from '../../assets/images/Footer3.png'
import linkedin from '../../assets/images/Footer5.svg'
import { Link } from 'react-router-dom'


const Footer = () => {
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Divider sx={{ color: '#3D3D3D' }} />
      <Container
        maxWidth='xl'
        sx={{
          px: { xs: 7, md: 10, lg: 20 },
          pt: { xs: 5, sm: 5, lg: 5, xl: 5 },
          pb: { xs: 5, sm: 5, lg: 5, xl: 5 },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          </Grid>
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: { xs: '16px', sm: '16px', md: '16px', lg: '18px', xl: '20px' },
                margin: { xs: '0', sm: '0', md: '0', lg: '0', xl: '0' },
              }}
            >
              © Ask Milo Pvt Ltd. All rights reserved {currentYear}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'center', md: 'flex-end' }, alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'center', md: 'flex-end' },
                gap: { xs: '10px', md: '20px' },
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Link to="https://www.facebook.com/profile.php?id=61565157899950" target="_blank">
                <img src={FbImg} alt="Facebook" />
              </Link>
              <Link to="https://x.com/ask_milo2024" target="_blank">
                <img src={TwitterImg} alt="Twitter" />
              </Link>
              <Link to="http://www.youtube.com/@AskMilo-g3d" target="_blank">
                <img src={UtubeImg} alt="YouTube" />
              </Link>
              <Link to="https://www.linkedin.com/company/ask-milo/?viewAsMember=true" target="_blank">
                <img src={linkedin} alt="LinkedIn" />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Footer;


