import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'

const HomePage = React.lazy(() => import('./pages/home'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

function App() {
  return (
    <Suspense fallback={<FallbackSpinner />}>
      <Routes>
        <Route
          element={
            <BlankLayout>
              <Outlet />
            </BlankLayout>
          }
        >
          <Route path='/401' element={<Page401 />} />
          <Route path='/404' element={<Page404 />} />
        </Route>

        <Route
          element={
            <UserLayout>
              <Outlet />
            </UserLayout>
          }
        >
          <Route path='/' element={<HomePage />} />
        </Route>

        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>
    </Suspense>
  )
}

export default App
